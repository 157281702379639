import React, { useState, useEffect, useContext } from "react";
import axios from 'axios';
import { Cookies } from 'react-cookie';
import { Modal } from 'react-bootstrap';

import { AppContext } from '../utils/AppContext';

const Home = () => {

    const [orderDetails, setOrderDetails] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [orderDetailsArray, setOrderDetailsArray] = useState(null);
    const [activeFilter, setActiveFilter] = useState("inProgress"); // Initialize active filter state
    const [audio] = useState(new Audio('/chime.mp3'));
    const [permissionGranted, setPermissionGranted] = useState(false);
    const [showPermissionModal, setShowPermissionModal] = useState(false);
    const [soundPlayedForNewOrder, setSoundPlayedForNewOrder] = useState(false);
    const [initialLoadComplete, setInitialLoadComplete] = useState(false);
    const [previousOrdersLength, setPreviousOrdersLength] = useState(0);

    const { tenantId, restaurantId, tenantName } = useContext(AppContext);

    useEffect(() => {
        if (initialLoadComplete && orderDetails.length > 0) {
            const newOrdersLength = orderDetails.length;
            if (newOrdersLength > previousOrdersLength && !soundPlayedForNewOrder) {
                if (permissionGranted == true) {
                    audio.loop = true; // Setting loop attribute to true to make the audio loop
                    audio.play();
                    // setSoundPlayedForNewOrder(true);
                } else {
                    requestSoundPermission();
                }
            } else if (newOrdersLength === previousOrdersLength) {
                // Reset soundPlayedForNewOrder flag when no new orders are detected
                setSoundPlayedForNewOrder(false);
            }
            // Update previous orders length for next comparison
            setPreviousOrdersLength(newOrdersLength);
        }
    }, [orderDetails, initialLoadComplete, previousOrdersLength, soundPlayedForNewOrder, audio]);


    useEffect(() => {
        if (!permissionGranted) {
            setShowPermissionModal(true);
        }
    }, [permissionGranted]);

    useEffect(() => {
        if (showPermissionModal) {
            requestSoundPermission();
        }
    }, [showPermissionModal]);

    const requestSoundPermission = () => {
        // Check if the browser supports the Permissions API
        if (navigator.permissions && navigator.permissions.query) {
            navigator.permissions.query({ name: 'audio' }).then(permissionStatus => {
                if (permissionStatus.state === 'granted') {
                    setPermissionGranted(true);
                } else if (permissionStatus.state === 'prompt') {
                    // Permission dialog will be shown, user will grant/deny the permission
                    permissionStatus.onchange = () => {
                        if (permissionStatus.state === 'granted') {
                            setPermissionGranted(true);
                        } else {
                            setPermissionGranted(false);
                        }
                    };
                } else {
                    // Permission denied
                    setPermissionGranted(false);
                }
            }).catch(error => {
                console.error('Error requesting permission:', error);
                setPermissionGranted(false);
            });
        } else {
            // Browser does not support the Permissions API
            console.warn('Browser does not support the Permissions API');
            setPermissionGranted(false);
        }
    };


    const handlePermissionModalClose = () => {
        setShowPermissionModal(false);
    };

    const handlePermissionGrant = () => {
        setPermissionGranted(true);
        setShowPermissionModal(false);
    };

    useEffect(() => {
        if (selectedOrder != null) {
            setOrderDetailsArray(JSON.parse(selectedOrder.order_details));
        }
    }, [selectedOrder]);

    useEffect(() => {
        loadUserData();

        const intervalId = setInterval(loadUserData, 10000);

        return () => clearInterval(intervalId);
    }, []);

    const loadUserData = () => {
        axios.get(`https://angeliques-api.vervedev.com/api/users/find/userid`, {
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.getItem("token")
            }
        })
            .then(userIdResponse => {
                axios.post(`https://angeliques-api.vervedev.com/api/checkout/list`, { restaurantId: restaurantId, tenantId: tenantId })
                    .then(res => {
                        setOrderDetails(res.data.orderDetails.reverse());
                        axios.post(`https://angeliques-api.vervedev.com/api/backend/status`, { restaurantId: restaurantId, tenantId: tenantId })
                            .then(ress => {
                                const booleanStatus = ress.data.data === 1 ? true : false;
                                if (booleanStatus === true) {
                                    setIsStatusToggleChecked(true);
                                }
                                setInitialLoadComplete(true);
                            });
                    })
                    .catch(error => {
                        console.error('Error fetching order details:', error);
                    });
            })
            .catch(error => {
                console.error('Error fetching user data:', error);
            });
    };

    const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        const options = {
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
        };
        return new Intl.DateTimeFormat('en-US', options).format(date);
    };

    const [currentDateTime, setCurrentDateTime] = useState('');

    useEffect(() => {

        const formatDate = () => {
            const date = new Date();
            const options = {
                weekday: 'long',
                month: 'long',
                day: 'numeric',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
                hour12: true,
            };
            return new Intl.DateTimeFormat('en-US', options).format(date);
        };

        setCurrentDateTime(formatDate());

        const interval = setInterval(() => {
            setCurrentDateTime(formatDate());
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const formatTimeRemaining = (order, status) => {

        if (status !== "In Progress") {
            return ""
        }

        const orderTimestamp = new Date(order).getTime();
        const currentTimestamp = new Date().getTime();
        const timeRemaining = (20 * 60 * 1000) - (currentTimestamp - orderTimestamp);

        if (timeRemaining <= 0) {
            return "Arriving";
        }

        const minutes = Math.floor((timeRemaining / (1000 * 60)) % 60);
        const seconds = Math.floor((timeRemaining / 1000) % 60);

        return `${minutes}m ${seconds}s`;
    };

    const handleCloseModal = () => {
        setSelectedOrder(null);
    };

    const handleOpenModal = () => {
        setSoundPlayedForNewOrder(true);
        audio.pause();
    };

    const logout = () => {
        sessionStorage.removeItem("token");
        window.location.reload();
    }

    const handleFilterChange = (filter) => {
        // requestSoundPermissionIos();
        setActiveFilter(filter);
    };

    const closeOrder = (orderId) => {
        axios.get(`https://angeliques-api.vervedev.com/api/users/find/userid`, {
            headers: {
                'Authorization': 'Bearer ' + sessionStorage.getItem("token")
            }
        })
            .then(userIdResponse => {
                axios.post(`https://angeliques-api.vervedev.com/api/checkout/close`, { orderId: orderId })
                    .then(res => {
                        loadUserData();
                        handleCloseModal();
                    })
                    .catch(error => {
                        console.error('Error fetching order details:', error);
                    });
            })
            .catch(error => {
                console.error('Error fetching user data:', error);
            });
    };

    const [isStatusToggleChecked, setIsStatusToggleChecked] = useState(false);
    const [isStatusToggleLoading, setIsStatusToggleLoading] = useState(false);

    const handleToggle = async () => {
        if (isStatusToggleLoading) return;

        setIsStatusToggleLoading(true);

        try {
            setIsStatusToggleChecked(!isStatusToggleChecked);
            if (!isStatusToggleChecked) {
                try {
                    setIsStatusToggleChecked(true);
                    await axios.post(`https://angeliques-api.vervedev.com/api/backend/status/update`, {
                        restaurantId: restaurantId,
                        tenantId: tenantId,
                        state: true
                    });
                } catch (error) {
                    console.error("Error updating restaurant status:", error);
                }

            } else {
                try {
                    setIsStatusToggleChecked(false);
                    await axios.post(`https://angeliques-api.vervedev.com/api/backend/status/update`, {
                        restaurantId: restaurantId,
                        tenantId: tenantId,
                        state: false
                    });
                } catch (error) {
                    console.error("Error updating restaurant status:", error);
                }
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setIsStatusToggleLoading(false);
        }
    };




    return (
        <div className="page-wrapper dashboard">
            {/* Permission Modal */}
            <Modal show={showPermissionModal} onHide={handlePermissionModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Sound Permission</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>This application requires permission to play sound for notifications.</p>
                    <p>Please grant the permission to continue.</p>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary" onClick={handlePermissionGrant}>Continue</button>
                    <button className="btn btn-secondary" onClick={handlePermissionModalClose}>Close</button>
                </Modal.Footer>
            </Modal>

            {/* Rest of the code */}
            <Modal show={!!selectedOrder} onShow={handleOpenModal} onHide={handleCloseModal} dialogClassName="custom-modal">
                <Modal.Header closeButton>
                    <Modal.Title>Order Details (<span>{selectedOrder && selectedOrder.customer_name}</span>)</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedOrder && orderDetailsArray && (
                        <div>
                            {orderDetailsArray.map((item, index) => (
                                <div key={index}>
                                    <p className="text-black order-item-name"><strong>{item.name}</strong></p>
                                    <p className="styled-border-left order-category-name">{item.categoryName}</p>
                                    <ul className="cart-item-options">
                                        {item.choices.map((choice, optionIdx) => (
                                            <div key={optionIdx} className="cart-item-option">
                                                <li className="order-choice">{choice.name} {choice.price > 0 && `(+${choice.price})`}</li>
                                            </div>
                                        ))}
                                    </ul>
                                    <ul>
                                        {item.options.map((option, optionIndex) => (
                                            <li key={optionIndex} className="order-option">{option.name} {option.price > 0 && `- $${option.price}`}</li>
                                        ))}
                                    </ul>
                                    <p><strong className="order-quantity">x{item.qty}</strong></p>
                                    <hr></hr>
                                </div>
                            ))}
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    {selectedOrder && <span><b>Order Total: ${(selectedOrder.order_total).toFixed(2)}</b></span>}

                    {selectedOrder && selectedOrder.order_status != "Complete" ? <button className="btn btn-primary" onClick={() => closeOrder(selectedOrder.id)}>Mark Completed</button> : null}
                    <button className="btn btn-secondary" onClick={handleCloseModal}>Close</button>

                </Modal.Footer>
            </Modal>


            <div className="app-nav">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="flex">
                                <div className="icon-wrapper">
                                    <img src="https://www.svgrepo.com/show/171081/store.svg" width="40" alt="store icon" />
                                </div>
                                <div className="nav-details">
                                    <h5>Online Orders</h5>
                                    <span>{tenantName}</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 text-center margin-top-10">
                            <span>Offline </span>
                            <label class="switch" id="switch">
                                <input type="checkbox" class="checkbox" checked={isStatusToggleChecked}
                                    onChange={handleToggle}
                                    disabled={isStatusToggleLoading} />
                                <span class="slider"></span>
                            </label>
                            <span> Online</span>
                        </div>
                        <div className="col-md-4">
                            <div className="flex flex-right flex-center">
                                <div className="time text-right">
                                    <h6>{currentDateTime} | <button className="logout-button" onClick={() => logout()}>Logout</button></h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <h1>{activeFilter === "inProgress" ? 'Active Orders' : 'Completed Orders'}</h1>
                    </div>
                    <div className="col-md-6">
                        <ul className="inline-list text-right">
                            <li className={activeFilter === "inProgress" ? "active" : ""} onClick={() => handleFilterChange("inProgress")}>In progress</li>
                            <li className={activeFilter === "completed" ? "active" : ""} onClick={() => handleFilterChange("completed")}>Completed</li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    {orderDetails
                        .filter(order => activeFilter === "inProgress" ? order.order_status !== "Complete" : order.order_status === "Complete")
                        .map((order, orderIdx) => (
                            <div className="col-lg-4 col-md-6 col-sm-12" key={orderIdx}>
                                <div className="order-wrapper" onClick={() => setSelectedOrder(order)}>
                                    <div className="row no-padding">
                                        <div className="col-md-7 col-xs-7 col-sm-7">
                                            <h4><b>{order.customer_name}</b></h4>
                                            <span>#{order.order_id}</span>
                                            <h6>{formatDate(order.order_time)}</h6>
                                        </div>
                                        <div className="col-md-5 col-xs-5 col-sm-5 text-right">
                                            <div className={order.order_status != "Complete" ? "order-status" : "order-status complete"}>
                                                <span>{order.order_status}</span>
                                            </div>
                                            <div className="order-time">
                                                <span className={formatTimeRemaining(order.order_time, order.order_status) !== "Arriving" ? "" : "Arriving"}>
                                                    {formatTimeRemaining(order.order_time, order.order_status)}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row no-padding">
                                        <div className="col-md-6">
                                            <span><b>Order Total</b></span>
                                        </div>
                                        <div className="col-md-6 text-right">
                                            <span>${(order.order_total).toFixed(2)}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
};

export default Home;
