import React, { useEffect } from "react";
import { useState, useContext } from 'react';
import axios from 'axios';
import Logo from '../img/perfect-prints-logo-light.png';
import { useNavigate } from "react-router-dom";
import { AppContext } from '../utils/AppContext';

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [restaurantId, setRestaurantId] = useState(null);
    const [tenantId, setTenantId] = useState(null);
    const [theme, setTheme] = useState([]);

    const { mainColor, updateMainColor, updateTenantId, updateRestaurantId, updateTenantName } = useContext(AppContext);

    function loadTheme() {
        axios.post("https://angeliques-api.vervedev.com/api/backend/init", { fqdn: window.location.hostname }).then((response) => {
            setRestaurantId(response.data.details[0].restaurantId);
            updateTenantId(response.data.details[0].tenantId)
            updateRestaurantId(response.data.details[0].restaurantId);
            updateTenantName(response.data.theme[0].tenantName)
            setTenantId(response.data.details[0].tenantId);
            setTheme(response.data.theme[0]);
            updateMainColor("#" + response.data.theme[0].primaryColour);
        });
    }

    useEffect(() => {
        loadTheme();
    }, []);

    let navigate = useNavigate();
    const requestSoundPermission = async () => {
        try {
            // Request permission to play sound
            await new Audio().play();
            console.log('Sound permission granted');
        } catch (error) {
            console.error('Sound permission denied:', error);
        }
    };

    function LoginUser() {
        axios.post("https://angeliques-api.vervedev.com/api/users/login", { email, password, fqdn: window.location.hostname }, { withCredentials: true }).then((response) => {
            if (response.data.message !== "success") {
                alert("error");
                return;
            } else {
                console.log(response.data.token)
                sessionStorage.setItem("token", response.data.token);
                requestSoundPermission();
                navigate("/");
                return;
            }
        });
    }

    return (
        <>
            <div className="login-wrapper">
                <div className="left-side">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <img src={theme.logoURL} alt="perfect-payday-logo" />
                                <h1>Login to online order portal</h1>
                                <p>Manage your mobile takeout orders with ease.</p>
                            </div>
                        </div>
                        <div className="row">
                            <form>
                                <div className="col-md-12">
                                    <label>Email</label>
                                    <input type="email" placeholder="john@doe.com" onChange={(e) => setEmail(e.target.value)} />
                                    <label>Password</label>
                                    <input type="password" placeholder="*******" onChange={(e) => setPassword(e.target.value)} />
                                    <button type="button" onClick={() => LoginUser()}>Login Now</button><span>Forgot your password? <a href="#">Reset Now</a>!</span>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="right-side" style={{ background: `url('${theme.loginImgURL}') no-repeat center center` }}>

                </div>
            </div>
        </>
    );
};


export default Login;