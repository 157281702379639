// AppContext.js
import React, { createContext, useState, useEffect } from 'react';

export const AppContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [mainColor, setMainColor] = useState(() => {
    return localStorage.getItem('mainColor') || '#999';
  });

  const [tenantId, setTenantId] = useState(() => {
    return localStorage.getItem('tenantId') || null;
  });

  const [restaurantId, setRestaurantId] = useState(() => {
    return localStorage.getItem('restaurantId') || null;
  });

  const [tenantName, setTenantName] = useState(() => {
    return localStorage.getItem('tenantName') || null;
  });

  useEffect(() => {
    document.documentElement.style.setProperty('--primary', mainColor);
  }, [mainColor]);

  const updateMainColor = (newColor) => {
    setMainColor(newColor);
    localStorage.setItem('mainColor', newColor);
  };

  const updateTenantId = (id) => {
    setTenantId(id);
    localStorage.setItem('tenantId', id);
  };

  const updateRestaurantId = (id) => {
    setRestaurantId(id);
    localStorage.setItem('restaurantId', id); 
  };

  const updateTenantName = (name) => {
    setTenantName(name);
    localStorage.setItem('tenantName', name);
  };

  return (
    <AppContext.Provider value={{ mainColor, updateMainColor, tenantId, updateTenantId, restaurantId, updateRestaurantId, tenantName, updateTenantName }}>
      {children}
    </AppContext.Provider>
  );
};
