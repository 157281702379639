import 'bootstrap/dist/css/bootstrap.min.css';
import './Css/Panel.css';
import './Css/App.css';

import Home from './Pages/Home';

import Login from './Pages/Login';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import PrivateRoutes from './utils/PrivateRoutes'

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>

        {/* End User Protected Routes */}
          <Route exact path="/" element={
            <PrivateRoutes>
                <Home/>
            </PrivateRoutes>
          }>
          </Route>     

          <Route path="/login" element={<Login />} exact/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
